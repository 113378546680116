import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "a-stable-foundation",
      "style": {
        "position": "relative"
      }
    }}>{`A stable foundation`}</h3>
    <p>{`In the last 7 months, we've been hard at work to be able to provide both
designers and developers with a wide range of building blocks. With a current
count of 41 reusable components, we're confident in our ability to build
scalable and inherently multibranded products.`}</p>
    <p>{`Having this stable foundation, we felt we've reached a certain level of maturity
that allows us to proudly publish a first major version. This major version also
allows us to clean up some loose ends that are a result of our initial
expansion-driven efforts. Be sure to check our migration guide for more info on
the breaking changes.`}</p>
    <List mdxType="List">
  <ListItem title="Migration guide" to="/updates/migration" description="" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "components-galore",
      "style": {
        "position": "relative"
      }
    }}>{`Components galore`}</h3>
    <p>{`As stated before, we currently offer 41 components. These components can be used
together to create patterns to craft exquisite user flows for all our products.`}</p>
    <p>{`Not only do these components lead to perfect consistency across our products,
but they also make the designer-developer communication smooth as silk. Without
effort, they are talking the same language. This is because we have identical
components in our design toolkit and our code-component library.`}</p>
    <h3 {...{
      "id": "updated-documentation-website",
      "style": {
        "position": "relative"
      }
    }}>{`Updated documentation website`}</h3>
    <p>{`Another point of focus leading up to the 1.0 release was a small overhaul of our
documentation website.`}</p>
    <p>{`While we were satisfied with the information it provided, we felt we needed to
further emphasize the baked-in multibranding we provide. For this reason,
switching brands on the documentation website will now truly immerse you in the
feeling of that selected brand.`}</p>
    <p>{`When it comes to the usability of the documentation website, we added a few
nifty tweaks. When in need of a specific component, you can simply search it via
our new search box, which will take you directly to your intended destination.
We also made the website fully responsive!`}</p>
    <h3 {...{
      "id": "new-utility-components",
      "style": {
        "position": "relative"
      }
    }}>{`New utility components`}</h3>
    <p>{`Last but not least we also added three new utility components to further
simplify your life as a developer. These components are wrappers around other
existing parts of our design system, but abstract away a layer of complexity
that you should not worry about.`}</p>
    <List mdxType="List">
  <ListItem title="Fonts" to="/components/fonts/code" description="" mdxType="ListItem" />
  <ListItem title="Logo" to="/components/logo/code" description="" mdxType="ListItem" />
  <ListItem title="Reset" to="/components/reset/code" description="" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}>{`What's next?`}</h3>
    <p>{`With the stable foundation we're releasing with this first major release, there
are a lot of next steps we can take in the next few months.`}</p>
    <ul>
      <li parentName="ul">{`Adding cross-team reusable patterns`}</li>
      <li parentName="ul">{`Providing tools for teams to allow them to reuse patterns across their own
products`}</li>
      <li parentName="ul">{`Focus on further integration with mobile apps`}</li>
      <li parentName="ul">{`Functional expansion of current component`}</li>
      <li parentName="ul">{`A further increase in components ...`}</li>
    </ul>
    <p>{`The exact focus for our next few months is still to be decided as we are
discussing how to completely align our vision with the whole of TPS. So stay
tuned for further news!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      